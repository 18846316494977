/*
 * @Author: 王星
 * @Date: 2021-07-09 10:47:04
 * @LastEditors: 王星
 * @LastEditTime: 2021-07-09 10:48:05
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RecruitJibDetail from '@/views/frame-home/recruitment/RecruitJibDetail.vue';
Vue.use(VueRouter);

const getRoutes = () => [
  {
    path: '/',
    redirect: 'mainpage'
  },
  {
    path: '/mainpage',
    component: () => import(/* webpackChunkName: 'MainPage' */ '@/views/frame-home/MainPage.vue'),
    children: [
      {
        path: '',
        redirect: 'homepage'
      },
      {
        path: 'homepage',
        component: () => import(/* webpackChunkName: 'homePage' */ '@/views/frame-home/homepage/HomePage.vue')
      },
      {
        path: 'about',
        component: () => import(/* webpackChunkName: 'about' */ '@/views/frame-home/aboutxf/AboutOur.vue')
      },
      {
        path: 'about/our',
        component: () => import(/* webpackChunkName: 'about' */ '@/views/frame-home/aboutxf/AboutOur.vue')
      },
      {
        path: 'news',
        component: () => import(/* webpackChunkName: 'news' */ '@/views/frame-home/news/NewsTab.vue'),
        children: [
          {
            path: '/',
            component: () => import(/* webpackChunkName: 'news' */ '@/views/frame-home/news/components/News.vue')
          },
          {
            name: 'news-detail',
            path: 'news-detail',
            component: () => import(/* webpackChunkName: 'news' */ '@/views/frame-home/news/components/NewsDetail.vue')
          }
        ]
      },
      {
        path: 'about/recruitment',
        component: () => import(/* webpackChunkName: 'recruitment' */ '@/views/frame-home/recruitment/WebRecruit.vue'),
        children: [
          {
            path: '',
            redirect: 'introduce'
          },
          {
            path: 'introduce',
            component: () => import('@/views/frame-home/recruitment/RecruitIntroduce.vue')
          },
          {
            name: 'jibDetail',
            path: 'jibDetail',
            component: RecruitJibDetail
          }
        ]
      },
      {
        path: 'technology',
        component: () => import(/* webpackChunkName: 'about' */ '@/views/frame-home/technology/CoreTechnology.vue')
      },
      {
        path: 'technology/:id',
        component: () => import(/* webpackChunkName: 'about' */ '@/views/frame-home/technology/CoreTechnology.vue')
      },
      {
        path: 'visual-center/ai-city',
        component: () => import(/* webpackChunkName: 'ai-city' */ '@/views/frame-home/ai-city/AiCity.vue')
      },
      {
        path: 'visual-center/algorithm-factory',
        component: () =>
          import(
            /* webpackChunkName: 'algorithm-factory' */ '@/views/frame-home/algorithm-factory/AlgorithmFactory.vue'
          )
      },
      {
        path: 'visual-center/industry',
        component: () => import(/* webpackChunkName: 'industry' */ '@/views/frame-home/AIIndustry/IndustryPage.vue')
      },
      {
        path: 'visual-center',
        component: () =>
          import(/* webpackChunkName: 'visual-center' */ '@/views/frame-home/visual-center/VisualCenter.vue')
      },
      {
        path: 'visual-center/visual-center',
        component: () =>
          import(/* webpackChunkName: 'visual-center' */ '@/views/frame-home/visual-center/VisualCenter.vue')
      },
      {
        path: 'productsystem',
        component: () =>
          import(/* webpackChunkName: 'productsystem' */ '@/views/frame-home/productsystem/ProductSystemPage.vue')
      },
      {
        path: 'productsystem/:id',
        component: () =>
          import(/* webpackChunkName: 'productsystem' */ '@/views/frame-home/productsystem/ProductSystemPage.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'notfound',
    component: () => import(/* webpackChunkName: 'NotFound' */ '../views/common/NotFound.vue')
  }
];

const router = new VueRouter({
  routes: getRoutes()
});

export default router;
