import { render, staticRenderFns } from "./RecruitJibDetail.vue?vue&type=template&id=9dc15448&scoped=true&"
import script from "./RecruitJibDetail.vue?vue&type=script&lang=js&"
export * from "./RecruitJibDetail.vue?vue&type=script&lang=js&"
import style0 from "./RecruitJibDetail.vue?vue&type=style&index=0&id=9dc15448&prod&lang=scss&module=true&"
import style1 from "./RecruitJibDetail.vue?vue&type=style&index=1&id=9dc15448&prod&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "9dc15448",
  null
  
)

export default component.exports