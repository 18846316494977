export const jibDetailData = [
  {
    id: 1,
    jibName: 'C++开发工程师',
    releaseTime: '2021-02-23',
    requireDatas: [
      {
        requireName: '岗位职责',
        datalist: [
          '主要负责公司后台服务程序的软件开发；',
          '参与公司后台服务程序的项目需求分析，规划技术方案；',
          '根据项目需求，负责后台服务程序的概要设计并定义模块接口；',
          '负责软件功能模块的研发，代码编制工作，确保软件开发按进度推进；',
          '负责软件模块的单元测试和性能测试，调试与维护工作；',
          '配合测试工程师对黑白盒测试进行回归，持续跟踪和改进软件缺陷；',
          '根据相关要求与规范编制对应的技术档案；',
          `有以下一点或多点经验优先：
          1.熟悉服务器端的负载优化和性能优化，有分布式大项目经验者优先；
          2.具有良好的编程风格，对重用，重构，设计模式有丰富经验者优先；
          3.对消息中间件有自己的理解，有使用开源中间件经验优先；
          4.有安防行业工作经验优先；`
        ]
      },
      {
        requireName: '职位要求',
        datalist: [
          '计算机或相关专业本科及以上学历，2年以上相关相关经验；个性乐观开朗，逻辑性及团队意识强，善于沟通合作；',
          '精通C/C++编程，熟悉Linux脚本，熟悉linux下gcc及make编程环境，熟悉STL C++标准库，熟悉常用数据结构、算法；',
          '熟悉linux下多线程，多进程，socket，文件系统等开发技术；',
          '具有强烈的责任感和团队精神，有一定的分析能力和表达能力，思维逻辑性较好；'
        ]
      }
    ]
  },
  {
    id: 2,
    jibName: '算法工程师',
    releaseTime: '2021-02-23',
    requireDatas: [
      {
        requireName: '岗位职责',
        datalist: [
          '负责计算机视觉和深度学习基本算法的开发与性能提升，涉及的问题包括但不限于：检测、跟踪、分类、语义分割、强化学习、3D视觉和图像处理等；',
          '推动计算机视觉算法和深度学习在众多实际应用领域的性能优化和落地；',
          '提出和实现最前沿的算法，保持算法在工业界和学术界的领先。',
          `加分项：
          1.获得过相关信息技术竞赛的荣誉，如ACM、NOI、百度之星等；
          2.有较强的学术比赛经验，如ImageNet，MSCOCO，LFW，MEGAFACE, FRVT, ICDAR等，并获得过突出成绩；
          3.有一年以上在BAT、人工智能知名创业公司、或国内外著名AI领域实验室进行视觉算法或工程相关的工作经验；
          `
        ]
      },
      {
        requireName: '职位要求',
        datalist: [
          '解决问题富有创造性思维，优秀的分析问题和解决问题的能力，对于挑战性问题的解决具有钻研精神；',
          '熟练掌握机器学习（特别是深度学习）和计算机视觉的基本方法，如分类、回归、聚类、概率模型等 D4；',
          '熟练掌握 C/C++ 编程，熟悉 Shell/Python/Matlab 等脚本语言，具有较强的算法实现能力；',
          ' 在国际顶尖会议或期刊，包括但不限CVPR, ICCV, ECCV, NIPS, ICML, AAAI, TPAMI, IJCV等，发表过论文者优先。'
        ]
      }
    ]
  },
  {
    id: 3,
    jibName: 'web前端开发工程师',
    releaseTime: '2021-02-23',
    requireDatas: [
      {
        requireName: '岗位职责',
        datalist: [
          '主导公司产品及现场项目交付的开发、参与部分设计；',
          '能够根据开发规范与流程独立完成编码、测试及相关文档；',
          '负责现有产品的功能升级、优化、提升产品的体验和稳定性；',
          '负责疑难技术问题的攻关解决；',
          '完成上级所安排的其他任务；'
        ]
      },
      {
        requireName: '职位要求',
        datalist: [
          '本科及其以上计算机相关专业，1年以上B/S前端开发经验，并具有一定的设计能力；对前端用户体验有一定认知；',
          '熟悉w3c标准，熟练使用前端技术es6、html5、css3、vue、npm、webpack、sass、less等；',
          '有安卓、微信公众号、小程序开发经验者优先；',
          '有一定的文档编写能力；',
          '热爱技术、思维敏捷、工作积极主动、责任心强，有一定的分析问题和解决问题的能力；有较强的沟通能力、理解能力、协作能力；'
        ]
      }
    ]
  },
  {
    id: 4,
    jibName: 'Gis软件工程师',
    releaseTime: '2021-02-23',
    requireDatas: [
      {
        requireName: '岗位职责',
        datalist: [
          '负责GIS公安行业应用项目开发工作；',
          '负责GIS产品的整体设计和核心模块设计；',
          '根据部门GIS产品规划，负责GIS产品的研发与项目应用；'
        ]
      },
      {
        requireName: '职位要求',
        datalist: [
          '本科及其以上计算机相关专业，具有2年以上GIS项目开发经验；个性乐观开朗，逻辑性及团队意识强，善于沟通合作；',
          '熟悉ArcGIS、SuperMap的二次开发；',
          '熟悉开源GIS（PostGIS、GeoServer、leaflet）开发优先；',
          '掌握Web（HTML5、JavaScript）相关开发技术，掌握java开发语言；'
        ]
      }
    ]
  },
  {
    id: 5,
    jibName: '客户经理',
    releaseTime: '2021-02-23',
    requireDatas: [
      {
        requireName: '岗位职责',
        datalist: [
          '主要负责公司全产品线产品在区域指定的销售及管理；',
          '管理现有客户，并布局新行业，负责新客户的开发与维护，渠道开发与管理；',
          '全权负责公司产品线的区域市场销售，达成公司销售目标；',
          '虚拟团队管理，大项目管理，包含销售规划、管理、执行；',
          '相关区域市场预测、客户关系维护、合同执行、回款回收跟进。'
        ]
      },
      {
        requireName: '职位要求',
        datalist: [
          '本科及以上学历； ',
          '具有1年以上销售/售前经验，有人工智能、安防行业工作经验优先； ',
          '具有政府信息化项目、公安项目业务经验有限；',
          '具备优秀的团队建设管理经验，强烈的进取心、结果导向、抗压能力强； ',
          '具备较强的市场分析、营销、推广能力和良好的协调能力，分析和解决问题的能力，能接受出差。'
        ]
      }
    ]
  },
  {
    id: 6,
    jibName: '解决方案工程师',
    releaseTime: '2021-02-23',
    requireDatas: [
      {
        requireName: '岗位职责',
        datalist: [
          '负责区域项目售前技术支持工作；',
          '能独立承担区域项目前期的方案编制、方案总体设计编写、交流汇报等工作；',
          '负责制定产品技术路线；',
          '负责对智慧商业、智慧安防、智能IPC相关方向的市场和竞争对手信息搜集和调研；',
          '对国内外竞品进行调研，把握同类产品发展趋势；',
          '负责对销售输入的客户和产品需求进行梳理分析；',
          '与各团队密切沟通合作、高效推动产品方案落地'
        ]
      },
      {
        requireName: '职位要求',
        datalist: [
          '本科及以上学历，985、211院校毕业优先，3年以上工作经验；',
          '语言表达能力强，，有一定的用户沟通经验和技巧；',
          '具备3年或以上智慧城市相关行业的设计、开发或工程实施经验，具有丰富的信息化集成的相关技术知识，能够同用户开展业务及技术交流；有较大型智慧城市项目售前技术支持经验者优先，参加或者主持过大型智慧城市等项目售前方案编写工作经验者优先；',
          '了解物联网、云计算、人工智能等新一代信息技术，熟悉智慧城市相关领域应用及解决方案，有较强的学习能力，能够熟悉掌握当代主流技术',
          '熟悉智能硬件产品的设计、研发、生产、销售全过程；',
          '精通办公软件、绘图软件，PPT制作水平熟练，能够独立撰写技术方案、投标文件等文档；',
          '团队意识强，有协作精神，能够与同事搞好团结，营造和谐氛围；'
        ]
      }
    ]
  },
  {
    id: 7,
    jibName: '项目经理',
    releaseTime: '2021-02-23',
    requireDatas: [
      {
        requireName: '岗位职责',
        datalist: [
          '作为项目经理，对项目设计、采购、实施等环节的成本、进度、质量目标负责；',
          '深入了解合同范围及客户需求，负责项目交付计划和技术方案的制定，负责管理、监控计划和技术方案的实施交付，对项目的验收和收入负责；',
          '负责相关的客户、团队成员、供应商的协调管理，保障项目顺利交付，提升客户满意度，促进市场的巩固和拓展。'
        ]
      },
      {
        requireName: '职位要求',
        datalist: [
          '本科及以上学历，工程管理、电子工程、智能交通/交通工程、计算机、自动控制、通信工程等相关专业；',
          '有交通、监控、安防、数据中心、消防及弱电等工程领域的项目管理经验，有智能交通、智慧城市、公共安全相关大型项目管理经验者优先；',
          '掌握项目管理理论，熟悉大型工程建设项目的流程、项目管理方法等；具备一定的领导技能，全面督导项目进度，有现场监督管理协调能力，对工程现场物料进出及施工人员安排、掌控具有突出的管理能力',
          '文字功底良好，具备演示文稿、技术方案、使用手册等技术文档撰写能力；',
          '自主学习能力强，能承受较大的工作压力，且有良好的客户服务意识和团队合作精神。',
          '品行端正，执行力强、具备良好的沟通协调能力和突发事件处理能力；具有PMP证书优先。'
        ]
      }
    ]
  },
  {
    id: 8,
    jibName: '现场服务专员',
    releaseTime: '2021-02-23',
    requireDatas: [
      {
        requireName: '岗位职责',
        datalist: [
          '负责公司产品的使用操作、日常巡检和问题反馈等工作；',
          '服从派驻地（上海）客户的管理，遵守派驻地的各项制度；',
          '服从公司领导的管理及节假日值班等工作安排；'
        ]
      },
      {
        requireName: '职位要求',
        datalist: [
          '学历不限，年龄30岁以下，有驻场服务经验优先；',
          '熟悉电脑操作，可熟练使用office办公软件等；',
          '工作态度积极，善于沟通，有服务意识和责任担当；'
        ]
      }
    ]
  },
  {
    id: 9,
    jibName: '现场工程师',
    releaseTime: '2021-02-23',
    requireDatas: [
      {
        requireName: '岗位职责',
        datalist: [
          '负责公司项目有关系统的现场勘测、设备安装、系统调试等；',
          '负责公司自研产品的安装调试及技术支持；',
          '完成项目实施所需文档，包括：配置方案、实施方案、测试方案等技术文档；',
          '根据上级安排，对客户进行产品培训，指导客户正确使用本公司产品；',
          '协助项目经理进行项目验收工作；',
          '与客户进行有效沟通，提高客户满意度。'
        ]
      },
      {
        requireName: '职位要求',
        datalist: [
          '理工科大专以上学历，计算机、通信、电子等相关专业；',
          '熟练安防行业知识及公司产品信息，熟悉Word、Excel、ppt、Visio、Project等办公管理工具及CAD制图软件；',
          '熟悉Linux和windows操作系统及常用操作指令，熟悉网络管理、系统集成相关知识；',
          '熟练视频监控的前端到后端平台运作的实施环节，包括不限于硬件，网络，系统，数据库，能保障服务器和应用服务都能正常运行并具备解决突然事件能力',
          '具备较强的动手实践能力和项目实施能力，能够组织大中型监控平台的实施，须掌握所有主流发布产品的独立安装、调试及操作使用。'
        ]
      }
    ]
  }
];
