<template>
  <div :class="$style.jibdetail">
    <div :class="$style.jobHeader">
      <b @click="goTop">关于芯翌</b><span> > </span><span>职位详情</span>
    </div>
    <div :class="$style.jobContent" v-if="contentShow">
      <div :class="$style.joblist">
        <div :class="$style.listName">
          <img src="@/assets/imgs/recuitment/job01.png" alt="" />
          职位列表
        </div>
        <el-menu background-color="#fff" text-color="#333" active-text-color="##333">
          <el-menu-item
            v-for="(item, index) in jibDetailData"
            :key="index"
            :class="menuShow === index ? 'el-menu-item-active' : ''"
            @click="ShowContent(index, item.jibName)"
          >
            <img src="@/assets/imgs/recuitment/job02.png" alt="" />
            <span>{{ item.jibName }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div :class="$style.jobBrif">
        <h3 class="jobName">{{ contentData.jibName }}</h3>
        <p v-if="contentData.releaseTime" :class="$style.time">发布时间：{{ contentData.releaseTime }}</p>
        <ol v-for="(item, index) in contentData.requireDatas" :key="index" start="1" :class="$style.jobList">
          <h4><span></span>{{ item.requireName }}</h4>
          <li v-for="(subitem, index) in item.datalist" :key="index">
            {{ subitem }}
          </li>
        </ol>
      </div>
    </div>
    <div :class="$style.images" v-else>
      <div :class="$style.imgContent">
        <img src="../../../assets/imgs/recuitment/zanwu.png" alt="">
        <p>暂无职位</p>
      </div>
    </div>
  </div>
</template>

<script>
import { jibDetailData } from './jibdetail';
export default {
  props: ['routeData', 'showContent'],
  data() {
    return {
      menuShow: 0,
      jibDetailData,
      contentData: undefined,
      contentShow: true
    };
  },
  created() {
    // 职位详情获取
    this.getData();
  },
  watch: {
    routeData(newValue) {
      this.contentData = this.jibDetailData.filter(item => item.jibName === newValue)[0];
      this.menuShow = this.jibDetailData.findIndex(item => item.jibName === newValue);
    },
    showContent() {
      this.contentShow = this.showContent;
    }
  },
  methods: {
    getData() {
      if (this.showContent) {
        if (this.$route.params.jobName) {
          this.contentData = this.jibDetailData.filter(item => item.jibName === this.$route.params.jobName)[0];
          this.menuShow = this.jibDetailData.findIndex(item => item.jibName === this.$route.params.jobName);
        } else {
          this.contentData = this.jibDetailData[0];
        }
      } else {
        this.contentShow = this.showContent;
      }
    },
    // 菜单点击
    ShowContent(index, itemName) {
      this.menuShow = index;
      this.contentData = this.jibDetailData.filter(item => item.jibName === itemName)[0];
    },
    // 返回上一页
    goTop() {
      this.$router.push('introduce');
      this.$emit('changeShowContent', 'true');
    }
  }
};
</script>

<style lang="scss" module>
.jibdetail {
  padding: 0 0 20px 0;
  background: #f3f5fc;
}
.jobList h4 {
  display: flex;
  align-items: center;
}
.jobHeader {
  width: 1200px;
  margin: 0 auto;
  height: 60px;
  font-size: 16px;
  color: #333333;
  line-height: 60px;
  b {
    cursor: pointer;
    font-weight: normal;
  }
  span:nth-of-type(1) {
    margin: 0 8px;
  }
  span:nth-of-type(2) {
    color: #f68014;
  }
}
.jobContent {
  display: flex;
  width: 1200px;
  margin: 0 auto;
}
.joblist {
  width: 336px;
  min-height: 843px;
  border: 1px solid #eeedeb;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 20px 40px -10px rgba(217, 221, 224, 0.5);
  .listName {
    height: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    vertical-align: middle;
    font-size: 24px;
    color: #333333;
    line-height: 100px;
    letter-spacing: 3px;
    padding: 0 14px;
    img {
      display: inline-block;
      width: 21px;
      height: 18px;
      margin-right: 8px;
    }
  }
}
.jobBrif {
  min-height: 843px;
  background: #ffffff;
  border: 1px solid #eeedeb;
  border-radius: 4px;
  box-shadow: 0px 20px 40px -10px rgba(217, 221, 224, 0.5);
  flex: 1;
  margin-left: 20px;
  padding: 38px 48px;
  h3 {
    font-size: 24px;
    color: #333333;
    line-height: 33px;
  }
  .time {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin-top: 4px;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 16px;
    margin: 24px 0 16px 0;
    span {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: #f68014;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  li {
    margin-left: 18px;
    font-size: 16px;
    color: #333333;
    line-height: 32px;
  }
}
h3,
h4,
p {
  margin: 0;
  padding: 0;
}
.images {
  background-color: #fff;
  border: 1px solid #eeedeb;
  border-radius: 4px;
  height: 843px;
  box-shadow: 0px 20px 40px -10px rgba(217,221,224,0.50);
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgContent {
  text-align: center;
  font-size: 24px;
  color: #999999;
  line-height: 33px;
  width: 161px;
  height: 188px;
  img {
    display: block;
    width: 161px;
    height: 155px;
  }
}
</style>
<style lang="scss" scoped>
.el-menu-item:hover {
  background: rgba(246, 128, 20, 0.11) !important;
}
.el-menu-item {
  background-color: #f6f8fa !important;
  margin-top: 14px;
  font-weight: 600;
  img {
    display: inline-block;
    margin: 0 16px 0 17px;
  }
}
/deep/.el-menu-item-active {
  background: rgba(246, 128, 20, 0.11) !important;
}
.el-menu {
  padding: 0 14px;
  border-right: none;
}
</style>
